<template>
	<input
		:id="'fileUploader' + uniqueId"
		class="fileUploader"
		type="file"
		:accept="accepts"
		multiple="multiple"
		@change="selectFile"
	/>
	<input
		type="button"
		id="uploadBtn"
		@click="openFileSelector"
		:value="getButtonText"
	/>
	<p id="file-type-disclaimer" v-text="accepts"></p>
	<div v-for="(file, index) in selection" :key="index">
		<div class="row file-selection">
			<p v-text="file.name"></p>
			<button @click="removeFileSelection(index)">&times;</button>
		</div>
	</div>

	<input
		type="text"
		class="hidden-validator"
		:required="isRequired"
		v-model="selectionClone"
		oninvalid="this.setCustomValidity(getErrorMessage())"
	/>
</template>

<script>
	import { validateFileUpload } from '../../../files';

	export default {
		name: 'File',
		props: {
			accepts: {
				type: String,
				default: ''
			},
			isRequired: {
				type: Boolean,
				default: false
			},
			uniqueId: {
				type: Number,
				default: 0
			}
		},
		emits: ['upload'],
		data() {
			return {
				selection: []
			};
		},
		computed: {
			selectionClone() {
				return this.selection;
			},
			getButtonText() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'Choose files:';
					case 'fi':
						return 'Valitse tiedostot:';
					default:
						return 'Choose files:';
				}
			},
			getErrorMessage() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'Please upload at least one file';
					case 'fi':
						return 'Lataa vähintään yksi tiedosto';
					default:
						return 'Please upload at least one file';
				}
			}
		},
		methods: {
			openFileSelector() {
				document.querySelector(`#fileUploader${this.uniqueId}`).click();
			},
			selectFile(e) {
				let amountStatus = true;
				let sizeStatus = true;

				e.target.files.forEach(file => {
					if (this.selection.length < 5) {
						if (validateFileUpload([file])) {
							this.selection.push(file);
						} else {
							sizeStatus = false;
						}
					} else {
						amountStatus = false;
					}
				});

				let shouldAlert = false;
				if (!amountStatus) {
					shouldAlert = true;
				}
				if (!sizeStatus) {
					shouldAlert = true;
				}
				if (shouldAlert) {
					const message = () => {
						switch (this.$store.state.lang) {
							case 'eng':
								return 'Please choose max 5 files that are max 20MB each';
							case 'fi':
								return 'Valitse enintään 5 enintään 20 Mt tiedostoa';
							default:
								return 'Please choose max 5 files that are max 20MB each';
						}
					};
					alert(message());
				}

				this.$emit('upload', this.selection);
			},
			removeFileSelection(index) {
				this.selection.splice(index, 1);
				this.$emit('upload', this.selection);
			}
		}
	};
</script>

<style scoped>
	input {
		padding: 10px;
	}

	.fileUploader {
		visibility: hidden;
		height: 0;
		width: 0;
		padding: 0;
		margin: 0;
	}

	#uploadBtn {
		padding: 8px;
		background: none;
		background-color: var(--light-grey);
		border: none;
		color: var(--black);
	}

	#uploadBtn:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	.file-selection {
		margin: 0 auto;
		width: 90%;
	}

	.file-selection p {
		color: var(--text);
		font-size: 0.8rem;
	}

	.file-selection button {
		background: none;
		padding: 0;
		margin: 0;
		border: 0;
		color: var(--black);
		padding-left: 10px;
	}

	.file-selection button:hover,
	.file-selection button:active {
		color: var(--accent);
		cursor: pointer;
	}

	#file-type-disclaimer {
		color: var(--text);
		opacity: 0.7;
		font-size: 0.8rem;
		padding: 0;
		padding-top: 2px;
		margin: 0;
		width: 100%;
		text-align: center;
	}
</style>
