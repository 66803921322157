export function validateFileUpload(files) {
	try {
		const sizeLimit = 20; // 20 megabytes
		const statuses = [];
		
		files.forEach((file) => {
			if (file.size / 1024 / 1024 > sizeLimit) {
				statuses.push(false);
			}
		});
		
		return statuses.length <= 0;
	} catch (e) {
		return true;
	}
}
